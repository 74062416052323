<template>
  <div
    class="inputNumber"
    :class="[
      { focus: active },
      { disabled: inputAttributes.disabled },
    ]"
  >
    <input
      v-if="active"
      ref="input"
      v-bind="inputAttributes"
      v-on="inputListeners"
    />
    <input
      v-else
      type="text"
      :value="displayValue"
      v-bind="inputAttributes"
      @click="onClick"
      @mousedown="onMouseDown"
    />
  </div>
</template>

<style scoped>
.inputNumber {box-sizing: border-box;width: 100%;border: 1px solid #e0e0e0;border-radius: 3px;}
.inputNumber input[type="number"] {display: block;box-sizing: border-box;width: 100%;padding: 0 6px;margin: 0;border: none;background-color: transparent;color: #666;font-size: 12px;line-height: 21px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;text-align: right;outline: none;}
.inputNumber input[type="number"]::placeholder {color: #bbb;}
.inputNumber input[type="text"] {display: block;box-sizing: border-box;width: 100%;padding: 0 6px;margin: 0;border: none;background-color: transparent;color: #666;font-size: 12px;line-height: 21px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;text-align: right;outline: none;}
.inputNumber input[type="text"]::placeholder {color: #bbb;}
.inputNumber.focus {border-color: #999;}
.inputNumber.disabled {border-color: #e0e0e0;background-color: #f0f0f0;}
.inputNumber.disabled input[type="number"] {color: #bbb;cursor: default;}
.inputNumber.disabled input[type="text"] {color: #bbb;cursor: default;}
</style>

<script>
import { numberWithCommas } from "@/utils/number";

function getAvailableKeyCodes() {
  return [
    8, // backspace
    46, // delete
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57, // numbers
  ];
}

export default {
  name: "InputPrice",
  data() {
    return {
      active: false,

      previousValue: this.$attrs.value,

      defaultAutoComplete: "off",
      defaultClasses: [],
      defaultMin: -9999999999999,
      defaultMax: 9999999999999,

      inputListeners: Object.assign({}, this.$listeners, {
        input: (event) => {
          let value = event.target.value
            .replace(/,/gi, "") // remove commas
            .replace(/[ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, "") // remove Korean
            .replace(/\s/gi, "");
          if (Number(value) < this.defaultMin) {
            value = `${this.defaultMin}`;
          } else if (this.defaultMax < Number(value)) {
            value = `${this.defaultMax}`;
          }

          event.target.value = value;
          this.$emit("input", value);
        },
        focus: (event) => {
          this.active = true;
          this.$emit("focus", event);
        },
        /**
         * @deprecated since of using syncfusion
         * @returns {string}
         */
        change: (event) => {
          const newValue = this.value;
          this.$emit("change", {
            ...event,
            event,
            value: newValue,
            previousValue: this.previousValue,
          });
          this.previousValue = newValue;
        },
        blur: (event) => {
          this.active = false;

          /**
           * @deprecated since of using syncfusion
           */
          this.$emit("blur", {
            ...event,
            event,
            value: this.value,
          });
        },
        keypress: (event) => {
          const { keyCode } = event;

          if (!getAvailableKeyCodes().includes(keyCode)) {
            if (keyCode === 45) {
              // minus
              event.target.value = -event.target.value;
              this.$emit("input", event.target.value);
            }

            event.preventDefault();
          }
        },
      }),
    };
  },
  computed: {
    displayValue() {
      return numberWithCommas(this.value);
    },
    value() {
      return this.$attrs.value;
    },
    inputAttributes() {
      const classes = Object.assign([], this.defaultClasses);
      if (typeof this.$attrs.class === "string") {
        classes.push(this.$attrs.class);
      } else if (Array.isArray(this.$attrs.class)) {
        classes.concat(classes);
      } else if (typeof this.$attrs.class === "object") {
        classes.push(this.$attrs.class);
      }

      return Object.assign({}, this.$attrs, {
        autocomplete: this.$attrs.autocomplete || this.defaultAutoComplete,
        class: classes,
        type: "number",
        min: this.$attrs.min || this.defaultMin,
        max: this.$attrs.max || this.defaultMax,
      });
    },
  },
  methods: {
    /**
     * @deprecated since of using syncfusion
     */
    focusIn() {
      this.$refs["input"].focus();
    },

    onClick() {
      this.active = true;
    },
    onMouseDown() {
      this.active = true;
    },
  },
};
</script>
