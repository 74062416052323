<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">Number</div>
            <ul class="content">
              <li class="item">
                <input-number
                  :allow-minus="true"
                  :disabled="numberDisabled"
                  v-model="number"
                />
                {{ number }}
              </li>
              <li class="item">
                <input type="checkbox" v-model="numberDisabled" />
              </li>
              <li class="item">
                <component-telephone v-model="telephone" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">Price</div>
            <ul class="content">
              <li class="item">
                <input-price v-model="priceValue" :disabled="priceDisabled" />
              </li>
              <li class="item">
                <input type="checkbox" v-model="priceDisabled" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">Date</div>
            <ul class="content">
              <li class="item date">
                <input-date type="lookup-condition" :disabled="dateDisabled" />
              </li>
              <li class="item">
                <input type="checkbox" v-model="dateDisabled" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">DateRange</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  :disabled="dateRangeDisabled"
                  v-model="dateRange"
                />
              </li>
              <li class="item">
                <input type="checkbox" v-model="dateRangeDisabled" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">Time</div>
            <ul class="content">
              <li class="item time">
                <input-time :disabled="timeDisabled" />
              </li>
              <li class="item">
                <input type="checkbox" v-model="timeDisabled" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">Birthday</div>
            <ul class="content">
              <li class="item birthday">
                <input-birthday
                  type="lookup-condition"
                  :disabled="birthdayDisabled"
                />
              </li>
              <li class="item">
                <input type="checkbox" v-model="birthdayDisabled" />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="viewGridBatchData">
            batchData 조회
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="viewGridData" :disabled="true">
            조회
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="progressTest"> Progress </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="std1Clicked"> std 1 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="std2Clicked"> std 2 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="com1Clicked"> comm 1 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="com2Clicked"> comm 2 </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button button-div="GET" @click.native="onClickSearchAsDetailsButton">
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-body">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridProps"
                @queryCellInfo="onQueryCellInfo"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0201">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">
                                Number({{ this.allowMinusNumber }})
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        :allow-minus="true"
                                        v-model="allowMinusNumber"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">
                                Date
                                {{ date }}
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date
                                        type="body-data"
                                        v-model="date"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">DateRange</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date-range type="body-data" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">Time</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">Birthday</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-birthday type="body-data" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">BizName</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input v-model="bizName" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
    </div>
    <ejs-dialog
      ref="popup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :visible="false"
      :enableResize="true"
      @overlayClick="onClickDialogOverlay"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">Number</div>
                  <ul class="content">
                    <li class="item">
                      <input-number :min="10" :allowMinus="true" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">Date</div>
                  <ul class="content">
                    <li class="item date">
                      <input-date type="lookupDetail-condition" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">DateRange</div>
                  <ul class="content">
                    <li class="item dateRange">
                      <input-date-range type="lookupDetail-condition" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">Time</div>
                  <ul class="content">
                    <li class="item time">
                      <input-time />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">Birthday</div>
                  <ul class="content">
                    <li class="item birthday">
                      <input-birthday type="lookupDetail-condition" />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button button-div="GET"> 조회 </erp-button>
            </li>
            <li class="reset">
              <erp-button button-div="GET"> 초기화 </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClosePopupButton">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .appContent .article-section.section-01 .section-body {overflow: hidden;border: none;}
body .appContent .article-section.section-0201 {height: auto;}
body .appContent .article-section.section-0201 .section-body {overflow: visible;border: none;}
body .appContent .article-section.section-0201 .body-data .field {width: calc(100% + 1px);}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit } from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import InputDate from "@/components/common/datetime/InputDate";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputTime from "@/components/common/datetime/InputTime";
import InputBirthday from "@/components/common/datetime/InputBirthday";
import moment from "moment";
import InputPrice from "@/components/common/number/InputPrice";
import {
  commonCodesGetStandardInfo,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "CommonComponentsSampleView",
  mixins: [commonMixin],
  components: {
    ComponentTelephone,
    InputPrice,
    InputBirthday,
    InputTime,
    InputDateRange,
    InputDate,
    InputNumber,
    EjsGridWrapper,
    ErpButton,
  },
  mounted() {
    setTimeout(() => {
      this.dateRange = {
        from: "2020-09-01",
        to: "2020-09-17",
      };
    }, 500);
  },
  data() {
    return {
      number: 0,
      dateRange: {
        from: null,
        to: null,
      },
      priceValue: "1",
      priceDisabled: false,

      date: null,
      numberDisabled: false,
      telephone: "",
      dateDisabled: false,
      dateRangeDisabled: false,
      timeDisabled: false,
      birthdayDisabled: false,

      allowMinusNumber: 0,
      bizName: null,
    };
  },
  computed: {
    gridProps() {
      return {
        columns: [
          {
            allowEditing: true,
            field: "myText",
            headerText: "Text",
          },
          {
            isPrimaryKey: true,
            field: "_rid",
            visible: false,
          },
          {
            allowEditing: true,
            field: "myNumber",
            headerText: "Number",
            isNumericType: true,
            type: "number",
            inputNumberProperty: {
              allowDot: true,
              allowMinus: true,
            },
            textAlign: "Right",
            /*,
              format: "N",
              valueAccessor: (field, data) => {
                const fieldData = data[field];
                if (fieldData === 0) {
                  return "-";
                } else {
                  return fieldData.toLocaleString() + " unit";
                }
              }*/
          },
          {
            allowEditing: true,
            field: "myCheckbox",
            headerText: "CB",
            displayAsCheckBox: true,
            editType: "booleanedit",
            textAlign: "Center",
            type: "boolean",
            width: 80,
          },
          {
            allowEditing: true,
            field: "selectAll_1",
            headerText: "A",
            displayAsCheckBox: true,
            editType: "booleanedit",
            textAlign: "Center",
            type: "boolean",
            isSelectAllColumn: true,
            width: 60,
          },
          {
            allowEditing: true,
            field: "selectAll_2",
            headerText: "B",
            displayAsCheckBox: true,
            editType: "booleanedit",
            textAlign: "Center",
            type: "boolean",
            isSelectAllColumn: true,
            selectAllCheckFunction: (record) => record.selectAll_1,
            width: 60,
          },
          {
            allowEditing: true,
            field: "myDate",
            headerText: "Date",
            isDateType: true,
            dateProperty: {
              format: "YYYY-MM-DD",
            },
          },
        ],
        dataSource: [...Array(10).keys()].map((n) => ({
          _rid: n + 1,
          myText: `No. ${(n + 1).toString().padStart(3, "0")}`,
          myNumber:
            n % 3 === 0 ? 0 : Math.ceil(Math.random() * (n + 1000000)) / 100,
          myDate: moment()
            .add(Math.floor(Math.random() * (n + 100)), "day")
            .format("YYYY-MM-DD"),
          myCheckbox: n % 2 === 0 ? true : false,
          selectAll_1: true,
          selectAll_2: n % 2 === 1 ? true : false,
        })),
        gridLines: "Both",
        provides: [Edit],
        rowHeight: 24,
      };
    },
  },
  methods: {
    onClickSearchAsDetailsButton() {
      this.$refs.popup.show();
    },
    onClickDialogOverlay() {
      this.$refs.popup.hide();
    },
    onClickClosePopupButton() {
      this.$refs.popup.hide();
    },
    onQueryCellInfo(args) {
      const {
        cell,
        column: { field },
      } = args;

      switch (field) {
        case "myText":
        case "myNumber":
        case "myDate":
        case "myCheckbox":
        case "selectAll_1":
        case "selectAll_2":
          cell.classList.add(this.$t("className.grid.modifyArea"));
          break;
      }
    },
    viewGridBatchData() {
      console.log(JSON.stringify(this.$refs.grid.getBatchChanges(), null, 2));
    },
    viewGridData() {
      console.log(
        JSON.stringify(this.$refs.grid.getBatchCurrentViewRecords(), null, 2)
      );
    },
    std1Clicked() {
      console.log("#### byLoad ####");
      this.calculateMultiExecute(10000, this.getStandardByLoad, true);
    },
    std2Clicked() {
      console.log("#### byDirect ####");
      this.calculateMultiExecute(10000, this.getStandardByDirect, true);
    },
    com1Clicked() {
      console.log("#### byLoad ####");
      this.calculateMultiExecute(100, this.getCommonCodeByLoad, false);
    },
    com2Clicked() {
      console.log("#### byDirect ####");
      this.calculateMultiExecute(100, this.getCommonCodeByDirect, false);
    },
    calculateMultiExecute(count, func, isConsoleLog = false) {
      const start = new Date();

      for (let n = 0; n < count; n++) {
        func.apply(this, [isConsoleLog]);
      }

      const elapsed = new Date() - start;
      console.log("소요 시간(ms):" + elapsed);
    },
    progressTest() {
      this.$EventBus.$emit("loaderOn");
      setTimeout(() => {
        this.$EventBus.$emit("loaderOff");
      }, 3000);
    },
    getStandardByLoad(isConsoleLog = false) {
      const bizName = commonCodesGetStandardInfo("bizName");

      if (isConsoleLog) {
        this.bizName = bizName;
        // console.log(bizName);
      }
    },
    getStandardByDirect(isConsoleLog = false) {
      const standardInfoObject = JSON.parse(
        sessionStorage.getItem("Standard-Infos")
      );
      const bizName = standardInfoObject["bizName"];

      if (isConsoleLog) {
        this.bizName = bizName;
        // console.log(bizName);
      }
    },
    getCommonCodeByLoad(isConsoleLog = false) {
      const comCodes = commonCodesGetCommonCode("HOLE_DIV");

      if (isConsoleLog) {
        // this.bizName = bizName;
        console.log(comCodes);
      }
    },
    getCommonCodeByDirect(isConsoleLog = false) {
      let loadedCommonCodesJson = JSON.parse(
        sessionStorage.getItem("Common-Codes")
      );
      const comCodes = loadedCommonCodesJson["HOLE_DIV"];

      if (isConsoleLog) {
        // this.bizName = bizName;
        console.log(comCodes);
      }
    },
  },
};
</script>

<style scoped></style>
